import React, { FC } from 'react';
import StaticCard from "./StaticCard";
import AnimatedCard from "./AnimatedCard";
import {useSelector} from "react-redux";
import {selectShuffle} from "../features/counter/applicationSlice";

type DateDisplayProps = {
    digit: number;
}

const DateDisplay:FC<DateDisplayProps> = ({ digit }) => {
        const shuffle = useSelector(selectShuffle);
        // assign digit values
        let currentDigit:string= `${digit}`;
        let previousDigit:string = `${digit - 1}`;


        // add zero
        if ( digit < 10 ) {
                currentDigit = `0${currentDigit}`;
        }
        if ( digit-1 < 10 ) {
                previousDigit = `0${previousDigit}`;
        }

        // shuffle digits
        const digit1 = shuffle
            ? previousDigit
            : currentDigit;
        const digit2 = !shuffle
            ? previousDigit
            : currentDigit;

        // shuffle animations
        const animation1 = shuffle
            ? 'fold'
            : 'unfold';
        const animation2 = !shuffle
            ? 'fold'
            : 'unfold';

        return(
            <div className={'flipUnitContainer'}>
                <StaticCard
                    position={'upperCard'}
                    digit={currentDigit}
                />
                <StaticCard
                    position={'lowerCard'}
                    digit={previousDigit}
                />

                <AnimatedCard
                    digit={digit1}
                    animation={animation1}
                />
                <AnimatedCard
                    digit={digit2}
                    animation={animation2}
                />
            </div>
        );
};

export default DateDisplay;