import React, { FC } from 'react';
import {Grid} from "@mui/material";
import DateDisplay from "./DateDisplay";

type DayOfYearProps = {
    date: Date;
}

const DayOfYear:FC<DayOfYearProps> = ({ date}) => {

    return(
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={4} >
                <DateDisplay digit={date.getMonth()+1} />
            </Grid>
            <Grid item xs={4} >
                <DateDisplay digit={date.getDate()} />
            </Grid>
            <Grid item xs={4} >
                <DateDisplay digit={date.getFullYear()} />
            </Grid>
        </Grid>
    );
};

export default DayOfYear;