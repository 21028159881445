import React, {FC} from "react";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import {LocalizationProvider} from "@mui/lab";
import {TextField} from "@mui/material";
import {
    selectDate,
    setDate,
    shuffle
} from '../features/counter/applicationSlice';
import { useSelector, useDispatch } from 'react-redux';


type DatePickerProps = {
    label: string;
}


const DatePick:FC<DatePickerProps> = ({label}) =>{
    const date = useSelector(selectDate);
    const dispatch = useDispatch();


    return(
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label}
                openTo="year"
                views={['year', 'month', 'day']}
                value={date}
                onChange={(newValue:any) => {
                    dispatch(shuffle())
                    dispatch(setDate(newValue))
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    )
}


export default DatePick;
