import React, { FC } from 'react';

type StaticCardProps = {
    digit: string;
    position:string;
}

// function component
const StaticCard:FC<StaticCardProps>  = ({ position, digit }) => {
    return(
        <div className={position}>
            <span>{digit}</span>
        </div>
    );
};

export default StaticCard;