import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './App.css';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector, useDispatch } from 'react-redux'
import IconButton from '@mui/material/IconButton';
import DatePick from "./components/DatePick";
import {Grid} from "@mui/material";
import { Typography } from '@mui/material';
import {selectDate, selectShuffle} from "./features/counter/applicationSlice";
import {addDays} from 'date-fns';
import DayOfYear from "./components/DayOfYear";

function App() {
  const date = useSelector(selectDate);
  const DiningAndOnlineCheckinDate = addDays(date, -60);
  const FinalPaymentDate = addDays(date, -30);
  const MagicBandsDate = addDays(date, -10);
  const MemMakerDate = addDays(date, -3);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  function getDateElement(text:string, date:Date, description:string ) {
    return <>
      <Typography variant="h2">
        {text}
      </Typography>
      <DayOfYear date={date}/>
    </>;
  }

  return (
      <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={6}
      >
        <Grid item xs={12}>
          <Typography variant="h1">
            Disney Date Calculator
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DatePick label={"My Disney Trip is"}/>
        </Grid>
        <Grid item xs={12}>
          {getDateElement("Dining Reservations (60 days in advance)", DiningAndOnlineCheckinDate, "All Guests may now make advance reservations for dining and other experiences up to 60 days in advance. In addition, Disney Resort hotel Guests can make reservations up to 60 days in advance plus the length of their stay (up to 10 days).")}
        </Grid>
        <Grid item xs={12}>
          {getDateElement("Online Check-in (60 days prior)", DiningAndOnlineCheckinDate, "Walt Disney World Resort guests may check-in online up to 60 days prior to their arrival date")}
        </Grid>
        <Grid item xs={12}>
          {getDateElement("Final Payment Date (30 days in advance)", FinalPaymentDate, "Walt Disney World package reservations must be paid in full 30 days in advance.")}
        </Grid>
        <Grid item xs={12}>
          {getDateElement("Magic Bands (10 days before your check-in date)", MagicBandsDate, "Disney MagicBands can be customized and ordred up to 10 days before your check-in date.")}
        </Grid>
        <Grid item xs={12}>
          {getDateElement("Memory Maker (3 days before your check-in)", MemMakerDate, "Memory Maker must be ordered at least 3 days before your check-in in order to receive the discounted price")}
        </Grid>
      </Grid>
  );
}

export default App;
