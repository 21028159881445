import React, { FC } from 'react';

type AnimatedCardProps = {
    animation: string;
    digit:string;
}

const AnimatedCard:FC<AnimatedCardProps> = ({ animation, digit}) => {

    return(
        <div className={`flipCard ${animation}`}>
            <span>{digit}</span>
        </div>
    );
};

export default AnimatedCard;